// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-single-item-js": () => import("/opt/build/repo/src/templates/single-item.js" /* webpackChunkName: "component---src-templates-single-item-js" */),
  "component---src-templates-single-tag-js": () => import("/opt/build/repo/src/templates/single-tag.js" /* webpackChunkName: "component---src-templates-single-tag-js" */),
  "component---src-templates-single-instructor-js": () => import("/opt/build/repo/src/templates/single-instructor.js" /* webpackChunkName: "component---src-templates-single-instructor-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructors-js": () => import("/opt/build/repo/src/pages/instructors.js" /* webpackChunkName: "component---src-pages-instructors-js" */),
  "component---src-pages-tags-js": () => import("/opt/build/repo/src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

